import React from "react"
import axios from "axios"
import { Form, Input, Alert } from "reactstrap"
import PqButton from "../components/pqButton"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/contactUs.css"
import "../styles/pqForms.css"

class Contacts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: null,
      submitting: false,
    }
  }

  onContactFormSubmit = e => {
    e.preventDefault()
    this.setState({ submitting: true })
    const form = e.target
    const url = "https://getform.io/f/9e74a5d2-0c71-4bd3-befa-5c8929cbb068"
    const data = new FormData(form)
    axios
      .post(url, data)
      .then(_ => {
        this.setState({ submitting: false, status: "success" })
        form.reset()
      })
      .catch(err => {
        this.setState({ submitting: false, status: "failure" })
      })
  }

  renderAlert = () => {
    const { submitting, status } = this.state
    const showAlert = status && !submitting
    if (!showAlert) return null
    const successMsg =
      "Thank you for contacting PriorityQ. We will be in touch with you shortly."
    const errorMsg =
      "Oops! An unexpected error occured. Please try again later."
    const alertMessage = status === "success" ? successMsg : errorMsg
    const alertColor = status === "success" ? "success" : "danger"
    return <Alert color={alertColor}>{alertMessage}</Alert>
  }

  render() {
    return (
      <Layout path={this.props.path}>
        <SEO title="Contact Us" />
        <div className="flex-row global-padding contact-us-container">
          <div className="flex-item contact-us-form">
            <div className="flex-center">
              <div className="flex-col-fix" style={{ width: "100%" }}>
                {this.renderAlert()}
                <h6>Get in touch</h6>
                <h1>Contact Us</h1>
                <div>Please send us an email to get in touch with us.</div>
                <div>
                  <Form onSubmit={this.onContactFormSubmit}>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter Name"
                      className="pqInput"
                      required
                    />
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Email"
                      className="pqInput"
                      required
                    />
                    <Input
                      type="textarea"
                      name="message"
                      id="message"
                      placeholder="Message"
                      className="pqInput"
                      required
                    />

                    <PqButton
                      customClassName="contactUs"
                      type="submit"
                      style={{ width: "200px" }}
                    >
                      Contact Us
                    </PqButton>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contacts
